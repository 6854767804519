<template>
    <div class="business-classification-set">
        <div class="business-classification">
            <div class="option-btn">
                <el-button type="primary" @click="addBusinessClassification">添加业务分类</el-button>
            </div>
            <business-classification-set-table
                ref="businessClassificationSetTable"
                :tableData="list"
                @edit-business-classification="editBusinessClassification"
                @merge-business-classification="mergeBusinessClassification"
                @refresh="refresh"
            ></business-classification-set-table>
            <!-- <table-page-tab
                :filterIndex="2"
                :pageTabs="pageTabJson"
            ></table-page-tab> -->
            <div class="work-table-footer" v-if="page.total>20">
                <el-pagination
                    :current-page="page.current"
                    :page-sizes="[20, 30, 50]"
                    :page-size="page.size"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    class="el-pagination-workTable"
                    layout="total, sizes, prev, pager, next, slot"
                    :total="page.total"
                >
                    <span class="pagination-text">
                        <span
                            >前往<el-input
                                v-model="pagerJump"
                                @keyup.enter.native="handlePagerJump"
                            ></el-input
                            >页</span
                        >
                        <span @click="handlePagerJump">跳转</span>
                    </span>
                </el-pagination>
            </div>
            <div class="work-table-footer" v-else></div>

            <!-- 添加/编辑业务分类弹窗 -->
            <el-dialog
                :title="title"
                :visible.sync="dialogVisible"
                width="500px"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false">
                <el-form :model="addOrEditForm" :rules="rules" ref="addOrEditForm" label-width="110px">
                    <el-form-item label="业务分类名称" prop="name">
                        <el-input v-model="addOrEditForm.name" placeholder="请输入业务分类名称"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleClose('addOrEditForm')">取消</el-button>
                    <el-button type="primary" @click="onsubmit('addOrEditForm')" :loading="submitLoading">确定</el-button>
                </span>
            </el-dialog>

            <!-- 合并弹窗 -->
            <el-dialog
                title="合并"
                :visible.sync="mergeDialogVisible"
                class="merge-dialog"
                width="500px"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false">
                <p class="tip">将原业务分类合并至目标业务分类，合并后推荐的业务分类将替换为目标业务分类</p>
                <el-form :model="mergeForm" :rules="rules" ref="mergeForm" label-width="110px">
                    <el-form-item label="原业务分类" prop="originalId">
                        <el-select v-model="mergeForm.originalId" disabled>
                            <el-option
                                v-for="item in list"
                                :key="'originalId_'+item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="目标业务分类" prop="toId">
                        <el-select v-model="mergeForm.toId" placeholder="请选择目标业务分类">
                            <el-option
                                v-for="item in options"
                                :key="'toId_'+item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleClose('mergeForm')">取消</el-button>
                    <el-button type="primary" @click="onsubmit('mergeForm')" :loading="submitLoading">确定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import BusinessClassificationSetTable from "./layout/business-classification-set-table.vue";
import TablePageTab from "#/component/workTable/table-page-tab.vue";
import pageTabJson from "./config/pageTab.json";
import businessClassificationService from '#/js/service/businessClassificationService.js';

export default {
    name: "BusinessClassificationSet",
    components: {
        TablePageTab,
        BusinessClassificationSetTable,
    },
    data() {
        return {
            isEdit: false,
            title: '',
            dialogVisible: false,
            mergeDialogVisible: false,
            loading: false,
            submitLoading: false,
            addOrEditForm: {
                id: '',
                name: '',
            },
            mergeForm: {
                originalId: '',  
                toId: '',  
            },
            rules: {
                name: [
                    { required: true, message: '请输入业务分类名称', trigger: 'blur' },
                    { max: 20, message: '业务分类名称不超过20个字' }
                ],
                toId: [
                    { required: true, message: '请选择目标业务分类', trigger: 'change' },
                ],
            },
            list: [],
            options: [],
            page: {
                total: 4,
                current: 1,
                size: 20,
            },
            pagerJump: 0,
        };
    },
    computed: {
        pageTabJson() {
            if (this.$store.state.user.userInfo.isCFUser) {
                return pageTabJson.CFUser;
            } else {
                return pageTabJson.notCFUser;
            }
        },
        isHuntingCompanySetPermission() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('UserPermissionSet');
        },
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
    },
    mounted() {
        if(this.$store.state.user.userInfo.isCFUser && (this.isAdministrator || this.isHuntingCompanySetPermission)) {
            this.loading = false;
            this.getOptionsList();
            this.getList();
        } else {
            shortTips("权限不足，请联系管理员");
            let tabItems = JSON.parse(sessionStorage.getItem("tabItems"));
            let _index = tabItems.findIndex(
                (item) => item.path === "/rolePermissionSet"
            );
            tabItems.splice(_index, 1);
            sessionStorage.setItem("tabItems", JSON.stringify(tabItems));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000);
        }
    },
    methods: {
        refresh() {
            this.getOptionsList();
            this.getList();
        },

        // 添加业务分类
        addBusinessClassification() {
            // // 重置
            // this.addOrEditForm = {
            //     id: '',
            //     name: ''
            // };
            this.isEdit = false;
            this.title = '添加';
            this.dialogVisible = true;
        },
        // 编辑业务分类
        editBusinessClassification(item) {
            this.addOrEditForm.id = item.id;
            this.addOrEditForm.name = item.name;
            this.isEdit = true;
            this.title = '编辑';
            this.dialogVisible = true;
        },
        // 合并业务分类
        mergeBusinessClassification(item) {
            // // 重置
            // this.mergeForm = {
            //     originalId: '',  
            //     toId: '',  
            // };
            this.mergeForm.originalId = item.id;
            this.mergeDialogVisible = true;
        },

        // 重置
        resetForm(ruleForm) {
            // console.log(ruleForm);
            this.$refs[ruleForm].resetFields();
            if(ruleForm == 'addOrEditForm') {
                this.addOrEditForm = {
                    id: '',
                    name: ''
                };
            } else {
                this.mergeForm = {
                    originalId: '',  
                    toId: '',  
                };
            }
        },
        handleClose(ruleForm) {
            if(ruleForm == 'addOrEditForm') {
                this.dialogVisible = false;
            } else {
                this.mergeDialogVisible = false;
            }
            this.resetForm(ruleForm);
        },
        // 提交
        onsubmit(ruleForm) {
            // 添加、编辑
            if(ruleForm == 'addOrEditForm') {
                this.$refs[ruleForm].validate((valid) => {
                    if (valid) {
                        this.submitLoading = true;
                        let params = Object.assign({}, this.addOrEditForm);
                        if(this.isEdit) {
                            businessClassificationService
                                .editBusinessClassification(params).then(res => {
                                    // console.log(res)
                                    shortTips('编辑成功');
                                    this.submitLoading = false;
                                    this.dialogVisible = false;
                                    this.resetForm(ruleForm);
                                    this.refresh();
                                }).catch(err => {
                                    console.log(err);
                                    this.submitLoading = false;
                                })
                        } else {
                            delete params.id;
                            businessClassificationService
                                .createBusinessClassification(params).then(res => {
                                    // console.log(res)
                                    shortTips('添加成功');
                                    this.submitLoading = false;
                                    this.dialogVisible = false;
                                    this.resetForm(ruleForm);
                                    this.refresh();
                                }).catch(err => {
                                    console.log(err);
                                    this.submitLoading = false;
                                })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            } else { // 合并
                this.$refs[ruleForm].validate((valid) => {
                    if (valid) {
                        this.submitLoading = true;
                        let params = Object.assign({}, this.mergeForm);
                        businessClassificationService
                            .megeBusinessClassification(params).then(res => {
                                // console.log(res)
                                shortTips('合并成功');
                                this.submitLoading = false;
                                this.mergeDialogVisible = false;
                                this.resetForm(ruleForm);
                                this.refresh();
                            }).catch(err => {
                                console.log(err);
                                this.submitLoading = false;
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        },

        // 点击分页之后，让数据定位到第一条
        setScrollTop(){
            // console.log(document.getElementsByClassName('el-table__body-wrapper'))
            // console.log(document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop)
            document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
        },
        // 分页
        handleSizeChange(val) {
            this.page.current = 1;
            this.page.size = val;
            this.getList();
            this.setScrollTop();
        },
        handleCurrentChange(val) {
            this.page.current = val;
            this.getList(true);
            this.setScrollTop();
        },
        handlePagerJump() {
            let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total / this.page.size);
            if (currentPager > 0 && currentPager <= currentPageTotal) {
                this.page.current = currentPager;
                this.getList(true);
                this.setScrollTop();
            }
        },
        // 获取用户权限业务分类设置列表
        getList(isSilent = false) {
            let params = Object.assign({}, this.params);
            if(!isSilent) {
                this.page.current = 1;
            }
            params.start = (this.page.current - 1) * this.page.size;
            params.take = this.page.size;
            this.$refs.businessClassificationSetTable.loading = true;
            businessClassificationService
                .getBusinessClassificationManageList(params).then(res => {
                    // console.log(res)
                    this.$refs.businessClassificationSetTable.loading = false;
                    this.page.total = res.total;
                    this.list = res.list;
                }).catch(err => {
                    this.$refs.businessClassificationSetTable.loading = false;
                })
        },
        // 获取业务分类列表
        getOptionsList() {
            let params = {
                start: 0,
                take: 100
            }
            businessClassificationService
                .getBusinessClassificationList(params).then(res => {
                    // console.log(res);
                    this.options = res.list;
                }).catch(err => {
                    console.log(err);
                })
        },
    },
};
</script>

<style lang="scss" scope>
.business-classification-set {
    height: 100%;
    .business-classification {
        height: 100%;
        min-height: 500px;
        padding: 10px 20px 0 20px;
        background: url("~@src/assets/images/home/home_bg.png") bottom center
            no-repeat;
        display: flex;
        flex-direction: column;
        .option-btn {
            padding: 0 20px;
            height: 52px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: end;
        }
        .merge-dialog {
            .tip {
                margin-bottom: 30px;
            }
            .el-form {
                .el-select {
                    width: 100%;
                }
            }
        }
        .work-table-footer {
            margin-top: 46px;
            height: 28px;
            overflow: unset;
        }
    }
}
</style>