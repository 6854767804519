<template>
    <div class="role-permission-set-table">
        <el-table
            v-loading="loading"
            :data="tableData"
            border
            style="width: 100%"
            height="700"
            tooltip-effect="light"
        >
            <el-table-column
                prop="name"
                align="center"
                :show-overflow-tooltip="true"
                label="业务类别名称"
            >
            </el-table-column>
            <el-table-column
                prop="recommendationCount"
                align="center"
                :show-overflow-tooltip="true"
                label="推荐数"
            >
            </el-table-column>
            <el-table-column
                prop="offerCount"
                align="center"
                :show-overflow-tooltip="true"
                label="offer数"
            >
            </el-table-column>
            <el-table-column
                prop="status "
                align="center"
                :show-overflow-tooltip="true"
                label="状态"
            >
                <template slot-scope="scope">
                    <span v-if="scope.row.status == '1'">启用</span>
                    <span v-if="scope.row.status == '2'" class="forbidden">禁用</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="排序">
                <template slot-scope="scope">
                    <span
                        class="optionText"
                        @click="handleSort('up',scope.row)"
                        >上移</span
                    >
                    <span
                        class="optionText"
                        @click="handleSort('down',scope.row)"
                        >下移</span
                    >
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <span
                        class="optionText"
                        @click="handleEdit(scope.row)"
                        >编辑</span
                    >
                    <span
                        class="optionText"
                        @click="handleMerge(scope.row)"
                        >合并</span
                    >
                    <span
                        class="optionText"
                        @click="handleUpdateStatus(scope.row)"
                    >
                        <span v-if="scope.row.status == '1'" class="forbidden">禁用</span>
                        <span v-if="scope.row.status == '2'">启用</span>
                    </span>
                </template>
            </el-table-column>
            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>
    </div>
</template>

<script>
import businessClassificationService from '#/js/service/businessClassificationService.js';

export default {
    props: {
        tableData: {
            type: Array,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        // 编辑
        handleEdit(item) {
            this.$emit("edit-business-classification", item);
        },
        // 合并
        handleMerge(item) {
            this.$emit("merge-business-classification", item);
        },
        // 排序（上移，下移）
        handleSort(type,item) {
            this.loading = true;
            let params = {
                id: item.id,
                sortType: type == 'up' ? 1 : 2, // 1：上移，2：下移
            }
            businessClassificationService
                .sortBusinessClassification(params).then(res => {
                    // console.log(res)
                    shortTips('操作成功');
                    this.loading = false;
                    this.$emit("refresh");
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                })
        },
        // 禁用启用
        handleUpdateStatus(item) {
            this.loading = true;
            let params = {
                id: item.id,
                status: item.status == '1' ? '2' : '1',
            }
            businessClassificationService
                .updateStatus(params).then(res => {
                    // console.log(res)
                    shortTips('操作成功');
                    this.loading = false;
                    this.$emit("refresh");
                }).catch(err => {
                    console.log(err);
                    this.loading = false;
                })
        },
    },
};
</script>

<style lang="scss" scope>
.role-permission-set-table {
    height: 60.6% !important;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    letter-spacing: 0px;
    .optionText {
        cursor: pointer;
        color: $primary;
        margin-right: 20px;
        &:last-child {
            margin: 0;
        }
    }
    .forbidden {
        color: #FF6564;
    }
    /deep/ .el-table {
        background-color: transparent;
        .el-table__body-wrapper {
            background-color: #fff;
        }
        th {
            padding: 0;
            height: 42px;
            line-height: 42px;
            background-color: #e8e8e8;
            border-color: #ccc;
            color: #666;
        }
        .el-table__empty-block {
            .el-table__empty-text {
                line-height: inherit;
            }
            .empty-img {
                display: inline-block;
                background: url("~@src/assets/images/home/icon_no_data.svg")
                    no-repeat;
                width: 240px;
                height: 228px;
            }
            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }
    }
}
</style>